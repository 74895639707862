<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">
          Provide Quote
        </h1>
      </div>
    </section>
    <!--- / Page Header -->

    <!-- Job Details -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">

        <breadcrumb v-if="job" :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>

        <!-- Is Loading -->
        <div v-if="isLoading" class="text-center">
          <spinner></spinner>
        </div>
        <!-- / Is Loading -->

        <!-- Loaded -->
        <div v-if="!isLoading && job" class="row">
          <div class="col-lg-7 position-relative pe-lg-5 mb-5 mb-lg-0" style="z-index: 1025;">
            <provide-quote-form :job="job"></provide-quote-form>
          </div>

<!--          <aside class="col-lg-5" style="margin-top: -6rem;">-->
<!--            <div class="sticky-top" style="padding-top: 6rem;">-->
<!--              <div class="card shadow-sm mb-3 mb-lg-0">-->
<!--                <div class="card-body">-->
<!--                  <h2 class="h3">Job Details</h2>-->
<!--                  {{ job.description }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </aside>-->
        </div>
        <!-- / Loaded -->

      </div>
    </section>
    <!-- / Job Details -->

  </div>
</template>

<script>
import Spinner from "../../../components/Spinner";
import jobs from "../../../api/jobs";
import Forms from "../../../mixins/Forms";
import Breadcrumb from "../../../components/Breadcrumb";
import Jobs from "../../../mixins/Jobs";
import {mapGetters} from "vuex";
import ProvideQuoteForm from "./ProvideQuoteForm";

export default {
  name: "Show.vue",
  components: {ProvideQuoteForm, Spinner, Breadcrumb},
  mixins: [Forms, Jobs],
  data() {
    return {
      isLoading: false,
      job: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    crumbs() {
      return [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "/jobs",
          label: "Jobs",
        },
        {
          href: "/jobs/" + (this.job ? this.job.id : null),
          label: this.job ? this.job.title : '-'
        },
        {
          href: '#',
          label: 'Provide Quote',
          active: true
        }
      ]
    },

    jobMembership() {
      return _.find(this.job.job_members, {user_role_id: this.userRole.id});
    },

    isRejected() {
      if (this.jobMembership) {
        return this.isJobRejected(this.job, this.jobMembership)
      }
      return false;
    },

    isAccepted() {
      if (this.jobMembership) {
        return this.isJobAccepted(this.job, this.jobMembership)
      }
      return false;
    },

    canAcceptOrReject() {
      return this.canAcceptOrRejectJob(this.job, this.jobMembership);
    }
  },
  watch: {},
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let vm = this;
      vm.job = null;
      vm.isLoading = true;
      vm.serverErrors = [];
      jobs.find(this.$route.params.id).then(r => {
        vm.isLoading = false;
        vm.job = r.data;
      }).catch(e => {
        console.log(e);
        vm.setAndNotifyErrors(e, 'Error loading job');
      });
    },

    provideQuote() {
      this.$router.push('/jobs/' + this.job.id + '/quotes/create');
    }
  }
}
</script>

<style scoped>

</style>